<script setup lang="ts">
import { first, isEmpty, last } from 'lodash-es';
import {
  ref,
  computed,
  toRef,
  useAsyncData,
  useConfigStore,
  useFlightService,
  useFlightRecommendation,
  useTracker,
  useRoute,
  useRouteBaseName,
  useDevice,
} from '#imports';

const flightRecommendRef = ref<HTMLElement | null>(null);

const device = useDevice();
const config = useConfigStore();
const { showFlightResult, cityImage, trackFlightPopular } = useFlightRecommendation();
const { jitsuEvent } = useTracker();
const route = useRoute();
const getRouteBaseName = useRouteBaseName();

const { data: flightFetchData, pending: flightFetchPending } = useAsyncData(
  'flight-recommendation',
  () =>
    useFlightService().getFlightHomepagePromos({
      country: config.country!,
      currency: config.currency!,
    }),
  {
    lazy: true,
    server: device.isCrawler,
    watch: [toRef(config, 'currency'), toRef(config, 'country')],
  },
);

const flightRecommendations = computed(() => {
  const section = [];
  const flightData = flightFetchData.value?.result.slice(0, 7);

  if (flightData) {
    if (flightData?.length > 5) {
      const middleIndex = Math.ceil(flightData?.length / 2);

      section.push(...[flightData.slice(0, middleIndex), flightData.slice(-middleIndex + 1)]);
    } else section.push(flightData);
  }

  return section;
});

const scrollTo = (direction: string) => {
  const elementNode = flightRecommendRef.value?.children[0]?.children;

  const element = direction === 'right' ? last(elementNode) : first(elementNode);

  if (element) {
    element!.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: direction === 'right' ? 'start' : 'end',
    });

    jitsuEvent('user-click-placeholder', {
      product: 'flight',
      object_name: 'exclusive-recommendation',
      object_parameter: direction,
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : getRouteBaseName(route) ?? '',
    });
  } else {
    jitsuEvent('user-slide-navigation', {
      product: 'flight',
      object_name: 'exclusive-recommendation',
      object_parameter: 'carousel',
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : getRouteBaseName(route) ?? '',
    });
  }
};
</script>

<template>
  <div>
    <div class="flex justify-between items-center px-15 mb-20">
      <h2 class="text-gray-darkest font-bold">
        {{ $t('flight.flightrecommendation') }}
      </h2>
      <div class="flex justify-between w-[60px]">
        <div
          class="w-25 h-25 flex justify-center items-center rounded-full shadow-md"
          @click="scrollTo('left')"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-left']"
          />
        </div>
        <div
          class="w-25 h-25 flex justify-center items-center rounded-full shadow-md"
          @click="scrollTo('right')"
        >
          <ApzIcon
            class="text-xsmall"
            :icon="['fas', 'chevron-right']"
          />
        </div>
      </div>
    </div>

    <div class="overflow-hidden">
      <div
        v-if="flightFetchPending"
        class="flex flex-col gap-y-10"
      >
        <FlightRecommendationMobilePlaceholder
          v-for="i in 2"
          :key="i"
        />
      </div>
    </div>

    <div
      v-if="!isEmpty(flightRecommendations)"
      ref="flightRecommendRef"
      class="flex flex-col gap-10 w-full overflow-x-auto snap-x snap-mandatory scroll-smooth px-15 relative scrollbar-hide"
    >
      <div
        v-for="(section, sectionIndex) in flightRecommendations"
        :key="sectionIndex"
        class="flex gap-10 max-h-[365px] w-full"
        data-testid="flight-recommended-card"
      >
        <NuxtLink
          v-for="(flight, flightIndex) in section"
          :key="flightIndex"
          :to="showFlightResult(flight)"
          rel="nofollow"
          class="w-[calc(50%-10px)] scroll-mx-15 snap-start flex-shrink-0 rounded text-white flex flex-col justify-between relative before:bg-gradient-to-b before:from-[#00000000] before:to-[#00000066] before:absolute before:inset-0 before:rounded last:after:block last:after:absolute last:after:-right-15 last:after:w-15 last:after:h-[1px] last:after:content-['']"
          @click="trackFlightPopular(flight)"
        >
          <NuxtImg
            :src="cityImage(flight.arrAirportCity)"
            :alt="flight.arrAirportCity"
            format="webp"
            width="160"
            height="117"
            sizes="sm:50vw"
            quality="50"
            fit="cover"
            :loading="flightIndex > 2 ? 'lazy' : 'eager'"
            class="aspect-[160/117] w-full rounded"
          />
          <div class="absolute top-10 left-10 z-[5]">
            <p class="text-xsmall">{{ $t('global.flighto') }}</p>
            <div class="flex gap-x-5 items-center">
              <ApzIcon
                icon="plane"
                size="xs"
              />

              <p class="font-bold text-small">
                {{ flight.arrAirportCity }}
              </p>
            </div>
          </div>
          <div class="absolute bottom-10 left-10 z-[5]">
            <p class="text-xsmall">{{ $t('search.frompromo') }}</p>
            <p class="text-small font-bold">
              {{ $c(flight.price, flight.currency) }}
            </p>
          </div>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
